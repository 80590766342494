var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('b-card',[_c('b-card-title',[_vm._v(" Configuración de la Empresa ")]),_c('validation-observer',{ref:"settingGeneral"},[_c('b-form',[_c('b-row',[_c('b-col',{attrs:{"cols":"12","md":"4"}},[_c('b-form-group',{attrs:{"label":"RUC","label-for":"igv"}},[_c('validation-provider',{attrs:{"name":"RUC","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"igv","type":"number","state":errors.length > 0 ? false:null},model:{value:(_vm.settings.ruc),callback:function ($$v) {_vm.$set(_vm.settings, "ruc", $$v)},expression:"settings.ruc"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"cols":"12","md":"4"}},[_c('b-form-group',{attrs:{"label":"Razon social","label-for":"business_name"}},[_c('validation-provider',{attrs:{"name":"Razon social","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"business_name","state":errors.length > 0 ? false:null},model:{value:(_vm.settings.businessName),callback:function ($$v) {_vm.$set(_vm.settings, "businessName", $$v)},expression:"settings.businessName"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"cols":"12","md":"4"}},[_c('b-form-group',{attrs:{"label":"Telefono","label-for":"phone"}},[_c('validation-provider',{attrs:{"name":"Telefono","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"phone","state":errors.length > 0 ? false:null},model:{value:(_vm.settings.phone),callback:function ($$v) {_vm.$set(_vm.settings, "phone", $$v)},expression:"settings.phone"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"cols":"12","md":"4"}},[_c('b-form-group',{attrs:{"label":"Dirección","label-for":"address"}},[_c('validation-provider',{attrs:{"name":"Dirección","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"address","state":errors.length > 0 ? false:null},model:{value:(_vm.settings.address),callback:function ($$v) {_vm.$set(_vm.settings, "address", $$v)},expression:"settings.address"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"cols":"12","md":"4"}},[_c('b-form-group',{attrs:{"label":"Email","label-for":"email"}},[_c('validation-provider',{attrs:{"name":"Email","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"email","state":errors.length > 0 ? false:null},model:{value:(_vm.settings.email),callback:function ($$v) {_vm.$set(_vm.settings, "email", $$v)},expression:"settings.email"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"cols":"12","md":"4"}},[_c('b-form-group',{attrs:{"label":"Horario","label-for":"schedule"}},[_c('validation-provider',{attrs:{"name":"Horario","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"schedule","state":errors.length > 0 ? false:null},model:{value:(_vm.settings.schedule),callback:function ($$v) {_vm.$set(_vm.settings, "schedule", $$v)},expression:"settings.schedule"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"cols":"12","md":"12"}},[_c('b-form-group',{attrs:{"label":"Numero de cuenta","label-for":"bank_accounts"}},[_c('validation-provider',{attrs:{"name":"Numero de cuenta","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-textarea',{attrs:{"id":"bank_accounts","state":errors.length > 0 ? false:null,"rows":"8"},model:{value:(_vm.settings.bankAccounts),callback:function ($$v) {_vm.$set(_vm.settings, "bankAccounts", $$v)},expression:"settings.bankAccounts"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"cols":"12"}},[_c('b-button',{attrs:{"variant":"primary","type":"submit"},on:{"click":function($event){$event.preventDefault();return _vm.validationForm.apply(null, arguments)}}},[_vm._v(" Guardar ")])],1)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }