<template>
  <b-card>
    <b-card-title>
      Configuración de la Empresa
    </b-card-title>
    <validation-observer ref="settingGeneral">
      <b-form>
        <b-row>
          <!-- RUC -->
          <b-col
            cols="12"
            md="4"
          >
            <b-form-group
              label="RUC"
              label-for="igv"
            >
              <validation-provider
                #default="{ errors }"
                name="RUC"
                rules="required"
              >
                <b-form-input
                  id="igv"
                  v-model="settings.ruc"
                  type="number"
                  :state="errors.length > 0 ? false:null"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>

          <!-- Business name -->
          <b-col
            cols="12"
            md="4"
          >
            <b-form-group
              label="Razon social"
              label-for="business_name"
            >
              <validation-provider
                #default="{ errors }"
                name="Razon social"
                rules="required"
              >
                <b-form-input
                  id="business_name"
                  v-model="settings.businessName"
                  :state="errors.length > 0 ? false:null"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>

          <!-- Phone -->
          <b-col
            cols="12"
            md="4"
          >
            <b-form-group
              label="Telefono"
              label-for="phone"
            >
              <validation-provider
                #default="{ errors }"
                name="Telefono"
                rules="required"
              >
                <b-form-input
                  id="phone"
                  v-model="settings.phone"
                  :state="errors.length > 0 ? false:null"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>

          <!-- Address -->
          <b-col
            cols="12"
            md="4"
          >
            <b-form-group
              label="Dirección"
              label-for="address"
            >
              <validation-provider
                #default="{ errors }"
                name="Dirección"
                rules="required"
              >
                <b-form-input
                  id="address"
                  v-model="settings.address"
                  :state="errors.length > 0 ? false:null"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>

          <!-- Email -->
          <b-col
            cols="12"
            md="4"
          >
            <b-form-group
              label="Email"
              label-for="email"
            >
              <validation-provider
                #default="{ errors }"
                name="Email"
                rules="required"
              >
                <b-form-input
                  id="email"
                  v-model="settings.email"
                  :state="errors.length > 0 ? false:null"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>

          <!-- Schedule -->
          <b-col
            cols="12"
            md="4"
          >
            <b-form-group
              label="Horario"
              label-for="schedule"
            >
              <validation-provider
                #default="{ errors }"
                name="Horario"
                rules="required"
              >
                <b-form-input
                  id="schedule"
                  v-model="settings.schedule"
                  :state="errors.length > 0 ? false:null"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>

          <!-- Banks Accounts -->
          <b-col
            cols="12"
            md="12"
          >
            <b-form-group
              label="Numero de cuenta"
              label-for="bank_accounts"
            >
              <validation-provider
                #default="{ errors }"
                name="Numero de cuenta"
                rules="required"
              >
                <b-form-textarea
                  id="bank_accounts"
                  v-model="settings.bankAccounts"
                  :state="errors.length > 0 ? false:null"
                  rows="8"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>

          <!--          <b-col cols="12">-->
          <!--            <b-form-group-->
          <!--              label="Terminos y condiciones"-->
          <!--              label-for="terms_conditions"-->
          <!--            >-->
          <!--              <quill-editor-->
          <!--                v-model="settings.termsAndConditions"-->
          <!--                :options="snowOption"-->
          <!--              />-->
          <!--            </b-form-group>-->
          <!--          </b-col>-->

          <!-- login button -->
          <b-col cols="12">
            <b-button
              variant="primary"
              type="submit"
              @click.prevent="validationForm"
            >
              Guardar
            </b-button>
          </b-col>
        </b-row>
      </b-form>
    </validation-observer>
  </b-card>
</template>

<script>
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import {
  BFormInput, BFormGroup, BForm, BRow, BCol, BButton, BCard, BCardTitle, BFormTextarea,
} from 'bootstrap-vue'
import { required } from '@validations'
import ToastificationContent from '@core/components/toastification/ToastificationContent'
// import { quillEditor } from 'vue-quill-editor'

export default {
  components: {
    ValidationProvider,
    ValidationObserver,
    BFormInput,
    BFormGroup,
    BForm,
    BRow,
    BCol,
    BButton,
    BCard,
    // quillEditor,
    BCardTitle,
    BFormTextarea,
  },
  data() {
    return {
      passValue: '',
      username: '',
      settings: {
        ruc: '',
        businessName: '',
        phone: '',
        address: '',
        email: '',
        schedule: '',
        bankAccounts: '',
      },
      required,
      snowOption: {
        theme: 'snow',
      },
      content: '',
    }
  },
  created() {
    this.fetchSettingGeneral()
  },
  methods: {
    async fetchSettingGeneral() {
      try {
        const response = await this.$http.get('admin/setting/business')
        const { business_name: businessName, bank_accounts: bankAccounts, ...rest } = response.data.payload.results
        this.settings.businessName = businessName
        this.settings.bankAccounts = bankAccounts
        Object.assign(this.settings, rest)
      } catch (e) {
        console.log(e)
      }
    },
    validationForm() {
      this.$refs.settingGeneral.validate().then(async success => {
        if (success) {
          try {
            await this.$http.put('admin/setting/business/update', this.settings)
            this.showToast({
              title: 'Actualizado con éxito',
              icon: 'CheckCircleIcon',
              variant: 'success'
            })
          } catch (e) {
            console.log(e)
            this.showToast({
              title: 'Oops!, ocurrió un error',
              icon: 'AlertTriangleIcon',
              variant: 'danger'
            })
          }
        }
      })
    },

    showToast({ title, icon, variant }) {
      this.$toast({
        component: ToastificationContent,
        props: {
          title,
          icon,
          variant,
        },
      })
    },
  },
}
</script>
<style lang="scss">
@import '@core/scss/vue/libs/quill.scss';
</style>
